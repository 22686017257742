import pan1 from "../images/pan1.jpg"
import pan3 from "../images/pan3.jpg"
import pan4 from "../images/pan4.png"
import pan5 from "../images/pan5.jpg"

export const sliderData = [
    {
        url:"https://imag.bonviveur.com/pan-de-trigo-rustico-foto-principal.jpg"
    },
    {
        url:"https://i.ytimg.com/vi/ahlZVBfmBcU/maxresdefault.jpg"
    },
    {
        url:pan1
    },
    {
        url:pan3
    },
    {
        url:pan4
    },
    {
        url:pan5
    },
];